import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';
import './ContactForm.css';

const ContactForm = () => {
  const form = useRef(); // Ref for accessing form DOM element
  const navigate = useNavigate();

  // Function to handle email sending
  const sendEmail = (e) => {
    e.preventDefault(); // Prevent default form submission behavior           

    emailjs.sendForm(
      'service_cdmt43v',     // Replace with your EmailJS Service ID
      'template_zaadac7',    // Replace with your EmailJS Template ID
      form.current,          // Reference to the form element
      'T34z6dQdSKe8HMKhp'         // Replace with your EmailJS User ID
    )
    .then((result) => {
        console.log('Email sent successfully:', result.text);
        alert('Thank you! Your message has been sent.');
        setTimeout(() => {
          navigate('/');
        }, 3000);

    }, (error) => {
        console.error('Failed to send email:', error.text);
        alert('Oops! Something went wrong, please try again later.');
    });

    e.target.reset(); // Clear form fields after submission
  };

  return (
    <div className="contact-form-container">
      <h1>
        Ready to <span className="highlight">Elevate</span> Your Business?
      </h1>
      <p className="subtext">
        Take the first step towards a more efficient and profitable business. Start your ipapo journey today!
      </p>
      <div className="form-container">
        <form ref={form} onSubmit={sendEmail}>
          <div className="form-row">
            <div className="form-group">
              <label>First Name*</label>
              <input type="text" name="first_name" placeholder="Enter here" required />
            </div>
            <div className="form-group">
              <label>Last Name*</label>
              <input type="text" name="last_name" placeholder="Enter here" required />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Email*</label>
              <input type="email" name="email" placeholder="Enter here" required />
            </div>
            <div className="form-group">
              <label>Phone Number*</label>
              <input type="tel" name="phone" placeholder="Enter here" required />
            </div>
          </div>
          <div className="form-group">
            <label>Company Name*</label>
            <input type="text" name="company" placeholder="Enter here" required />
          </div>
          <div className="contact-form-submit-button">
          <button type="submit" className="submit-button">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;