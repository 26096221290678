import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({
  title,
  description,
  canonical,
  openGraphImage,
  keywords,
  type = 'website',
  twitterHandle,
  url = window.location.href,  // defaulting to current URL
}) => {
  return (
    <Helmet>
      {/* Title Tag */}
      <title>{title ? `${title} ` : 'My Website'}</title>

      {/* Meta Description */}
      <meta name="description" content={description || "Default description for my website"} />

      {/* Meta Keywords */}
      <meta name="keywords" content={keywords || "default, keywords, for, website"} />

      {/* Canonical Link to avoid duplicate content issues */}
      {canonical && <link rel="canonical" href={canonical} />}

      {/* Open Graph (OG) Tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title || "My Website"} />
      <meta property="og:description" content={description || "Default description for my website"} />
      <meta property="og:url" content={url} />
      {openGraphImage && <meta property="og:image" content={openGraphImage} />}
      
      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title || "My Website"} />
      <meta name="twitter:description" content={description || "Default description for my website"} />
      {twitterHandle && <meta name="twitter:creator" content={twitterHandle} />}
      {openGraphImage && <meta name="twitter:image" content={openGraphImage} />}

      {/* Structured Data (JSON-LD for rich snippets) */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "${url}",
            "name": "${title || 'My Website'}",
            "description": "${description || 'Default description for my website'}",
            "keywords": "${keywords || 'default, website, keywords'}"
          }
        `}
      </script>
    </Helmet>
  );
};

export default SEO;