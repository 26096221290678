import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Footer from './Footer';
import ReactCardFlip from "react-card-flip";
import SEO from "./seo";



import ContactForm from './ContactForm';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';

function App() {
  return (
    <Router>
      <div className="App">
        {/* Global SEO setup for the main page */}
        <SEO title="iPapo - One Platform, Infinite Possibilities" description="Discover endless possibilities with iPapo, your all-in-one platform." image="assets/Papo.png" />
        
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <SEO 
                title="Home - iPapo"
                description="Welcome to iPapo, the platform that streamlines workflows and boosts productivity."
                image="assets/Papo.png"
              />
              <HomePage />
            </>
          } />
          <Route path="/contact" element={
            <>
              <SEO 
                title="Contact Us - iPapo"
                description="Get in touch with iPapo for inquiries or support."
                image="assets/Papo.png"
              />
              <ContactForm />
            </>
          } />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}
function Header() {
  return (
    <header className="navbar">
      <div className="logo">
      <Link to="/">
        <img src="assets/Papo3.png" alt="ipapo logo" className="ipapologo" />
        </Link>
      </div>
      <Link to="/contact" className="contactbutton">
        <button className="cta-button">Book A Demo</button>
      </Link>
    </header>
  );
}
function HomePage() {
  return (
    <>
      <Hero />
      <MediaQuery minWidth={480}>
      <MainCardContainer />
      </MediaQuery>
      <MediaQuery maxWidth={480}>
      <Mobile/>
      </MediaQuery>


      <AssetManagement />
      <StartBuilding />
      
      <Testimonials />
      <JoinWaitlistCard />
      {/*<Footerlogo />*/}


      
    </>
  );
}

function Hero() {
  return (
    <section className="hero">
      <h1>
        Supercharge Your Business<br />
        With <span className='Color_Span'>One Platform</span>
      </h1>

      <p>
        Streamline workflows and boost productivity by unifying assets,<br />managing people, and optimizing operations.
      </p>
      <MediaQuery minWidth={480}>
      <br />
      <br />
      </MediaQuery>
      <Link to="/contact">
        <button className="cta-2-button">Request A Demo</button>
      </Link>
      <br /><MediaQuery minWidth={480}>
      <br />
      <br />
      <br />
      </MediaQuery>
      <div className="client-list-wrapper">
        <div className="client-list-container">
          <img className="client-list" src="assets/Client-list.png" alt="Client List" />
          
          
        </div>
        <p className="paragraph">
        Trusted by fast-growing companies worldwide
      </p>
      </div>
      
    </section>
  );
}


function Mobile() {
  return (
    <div className="App">
      <div className="cards-container">
        <Crd
          title="Your Business,"
          subtitle="At a Glance"
          description={<>See the big picture. Track key metrics, <span style={{ color: '#02b3bc',}}>analyze trends</span>, and make informed decisions.</>}
          image="assets/dashboard.gif" // Replace with the actual image path
          imagePosition="left"
        />
        <div className="mobileright">
        <Crd
          title={<><div style={{ textAlign: 'right' }}>Build Your Own</div></> }
          subtitle={<><div style={{ textAlign: 'right' }}>Business Powerhouse</div></> }
          description={
            <>
             <div style={{ textAlign: 'right' }}>
              <span style={{ color: '#02b3bc',}}>Customize</span> ipapo to fit your exact needs. Choose the
              modules that matter most to you.
              </div>
            </>
          }
          image="assets/blocks.gif" // Replace with the actual image path
          imagePosition="right"
        />
        </div>
        <Crd
          title={<><span style={{ fontSize:'20px'}}>Connect Everything</span> </>}
          subtitle={<><span style={{ fontSize:'10px'}}>Everywhere</span></>}
          description={<>Seamlessly <span style={{ color: '#02b3bc',}}>integrate</span> your existing tools and devices.</>}
          image="assets/integration.gif" // Replace with the actual image path
          imagePosition="left"
        />
        <Crd
          title={<><div style={{ textAlign: 'right' }}>Scan Your</div></> }
          subtitle={<><div style={{ textAlign: 'right' }}>Way to Success</div></> }
          description={
            <>
             <div style={{ textAlign: 'right' }}>Pay only for what you need and focus on <span style={{ color: '#02b3bc',}}>growth</span>, not costs.
             </div>
            </>
          }
          image="assets/dollar.gif" // Replace with the actual image path
          imagePosition="right"
        />
      </div>
    </div>
  );
}

function Crd({ title, subtitle, description, image, imagePosition }) {
  return (
    <div className={`crd ${imagePosition === 'right' ? 'reverse' : ''}`}>
      <div className="crd-image-container">
        <img src={image} alt="Card icon" className="crd-image" />
      </div>
      <div className="crd-content">
        <h3 className="crd-title">{title}</h3>
        <h2 className="crd-subtitle">{subtitle}</h2>
        <p className="crd-description">{description}</p>
      </div>
    </div>
  );
}




class FlippableCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFlipped: false
    };
  }

  handleMouseEnter = () => {
    this.setState({ isFlipped: true });
  };

  handleMouseLeave = () => {
    this.setState({ isFlipped: false });
  };

  render() {
    const { frontContent, backContent } = this.props;
    return (
      <ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="horizontal">
        <div
          className="small-card visible-card"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {frontContent}
        </div>
        <div
          className="small-card visible-card"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {backContent}
        </div>
      </ReactCardFlip>
    );
  }
}




function MainCardContainer() {
  return (
    <div className="main-card-container">
      <div className="large-card">
        <div className="small-card-row">
          {/* First Row - Left */}
          <FlippableCard
            frontContent={
              <>
              
                <img className="card-gif" src="assets/dashboard.gif" alt="GIF" />
                <div classname="first-card">
                <div className="card-text">
                  <h3>Your Business,</h3>
                  <h2>At a Glance</h2>
                </div>
                </div>
              </>
            }
            backContent={
              <>
                <div className="card-text">
                  <p>See the big picture. Track key metrics, <span>analyze trends</span>, and make informed decisions.</p>
                </div>
              </>
            }
          />

          {/* First Row - Middle (Blank Card) */}
          <MediaQuery minWidth={480}>
          <div className="small-card blank-card">
            <p>Transform your business with cutting-edge<br/> technology.</p>
          </div>
          </MediaQuery>

          {/* First Row - Right */}
          <FlippableCard
            frontContent={
              <>
                <div className="card-text">
                  <h3>Build Your Own</h3>
                  <h2>Business Powerhouse</h2>
                </div>
                <img src="assets/blocks.gif" alt="GIF" className="card-gif" />
              </>
            }
            backContent={
              <>
                <div className="card-text">
                  <p><span>Customize</span> ipapo to fit your exact needs. Choose the modules that matter most to you</p>
                </div>
              </>
            }
          />
        </div>

        <div className="small-card-row">
          {/* Second Row - Left (Blank Card) */}
          <MediaQuery minWidth={480}>
          <div className="small-card blank-card">
            <p>Experience the future of work.</p>
          </div>
          </MediaQuery>

          {/* Second Row - Middle */}
          <FlippableCard
            frontContent={
              <>
              <MediaQuery minWidth={480}>
              <div className="card-text">
                  <h2>Connect Everything</h2>
                  <h3>Everywhere</h3>
                </div>
                <img src="assets/integration.gif" alt="GIF" className="card-gif" />
              </MediaQuery>
              <MediaQuery maxWidth={480}>
                <img src="assets/integration.gif" alt="GIF" className="card-gif" />
                <div classname="first-card">
                <div className="card-text">
                  <h2>Connect Everything</h2>
                  <h3>Everywhere</h3>
                </div>
                </div>
                </MediaQuery>
              </>
            }
            backContent={
              <>
                <div className="card-text">
                  <p>Seamlessly <span>integrate </span> your existing tools and devices.</p>
                </div>
              </>
            }
          />

          {/* Second Row - Right */}
          <FlippableCard
            frontContent={
              <>
                <div className="card-text">
                  <h2>Scale Your Business</h2>
                  <h3>Your Way</h3>
                </div>
                <img src="assets/dollar.gif" alt="GIF" className="card-gif" />
              </>
            }
            backContent={
              <>
                <div className="card-text">
                  <p>Pay only for what you need and focus on <span>growth</span>, not costs.</p>
                </div>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
}



const AssetManagement = () => {
  // Define state to keep track of the selected button
  const [selected, setSelected] = useState('asset');

  // Define the subheadings and paragraphs for each button
  const content = {
    asset: {
      subheading: "Assets Management, Reimagined",
      paragraph: "Take control of your assets. ipapo empowers you to track, manage, and optimize your assets, from inventory to equipment. With real-time insights and automated workflows, you can streamline operations and reduce costs.",
    },
    people: {
      subheading: "People Management, Reinvented",
      paragraph: "Empower your team with the right tools and resources. Manage team roles, monitor productivity, and facilitate collaboration. Ipapo helps you create a cohesive and efficient team environment.",
    },
    operations: {
      subheading: "Operations Optimized",
      paragraph: "Optimize your operations for maximum efficiency. Track workflows, improve process transparency, and reduce bottlenecks. Ipapo provides the tools to enhance your operational strategy.",
    },
  };

  return (
    <div className="Big-container">
      <div className="left-side">

      <MediaQuery minWidth={480}>
        <h1 className="title">The All-in-One Solution<br/>for Your Business<br/>Needs</h1>
        </MediaQuery>

        <MediaQuery maxWidth={480}>
          <h1 className="title">The All-in-One Solution for Your Business Needs</h1>
        </MediaQuery>



        {/* Display subheading based on selected button */}
        <h3 className="subtitle">{content[selected].subheading}</h3>

        {/* Display paragraph based on selected button */}
        <p className="description">{content[selected].paragraph}</p>

        

        
        {/* Buttons to switch between different content */}
        <div className="button-group">
          <button
            className={`threebutton ${selected === 'asset' ? 'active' : ''}`}
            onClick={() => setSelected('asset')}
          >
            Assets
          </button>
          <button
            className={`threebutton ${selected === 'people' ? 'active' : ''}`}
            onClick={() => setSelected('people')}
          >
            People
          </button>
          <button
            className={`threebutton ${selected === 'operations' ? 'active' : ''}`}
            onClick={() => setSelected('operations')}
          >
            Operations
          </button>
        </div>
      </div>

      
      <div className="right-side">
        <div className="image-frame">
          <img src="assets/threeframegroup.png" alt="Description" className="threeframegroup" />
        </div>
      </div>
      
    </div>
  );
};


function StartBuilding() {
  
    const videoRef = useRef(null);
  
    useEffect(() => {
      const handleFullScreen = (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (videoRef.current && videoRef.current.requestFullscreen) {
              videoRef.current.requestFullscreen();
            }
          }
        });
      };
  
      // Set up the Intersection Observer
      const observer = new IntersectionObserver(handleFullScreen, {
        threshold: 0.8, // Trigger when 80% of the video is visible
      });
  
      if (videoRef.current) {
        observer.observe(videoRef.current);
      }
  
      return () => {
        if (videoRef.current) {
          observer.unobserve(videoRef.current);
        }
      };
    }, []);
  return (
    <section className="startbuild">
      <h1>Start building your <span className='Color_Span'>Custom Application</span><span className='white_In'></span><br/> in <span className='Color_Span'>Seconds</span></h1>
      <p>No coding required. Simply select the features, customize, and launch. It's that easy.</p>

      <div className="button-group-">
        <br />
        <Link to="/contact">
          <button className="cta-2-button">Book A Demo</button>
        </Link>
        <MediaQuery minWidth={480}>
        <br />
        <br />
        <br />
        <br />
        <br />
        </MediaQuery>
        <div className="outer-video-card">
          <div className="video-card">
            <video src="assets/ipapowebsitevideo.mp4" className="card-video" autoPlay loop muted></video>
          </div>
        </div>
      </div>
    </section>
  );
}


{/*function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const testimonials = [
    {
      text: "“We celebrate each other's victories, support each other through challenges, and share our knowledge and experiences.”",
      image: "assets/-1(1).jpg",
      name: "Alex R.",
      role: "UI/UX Design student",
    },
    {
      text: "“I can always rely on this program for resources and support, which has been invaluable in my learning.”",
      image: "assets/-1 (1).jpg",
      name: "Emma S.",
      role: "Web Development student",
    },
    {
      text: "“The curriculum is thorough, and the mentors are always ready to help with any questions or roadblocks.”",
      image: "assets/-1 (5).jpg",
      name: "Liam T.",
      role: "Data Science student",
    },
  ];

  const handleLeftClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  const handleRightClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <section className="testimonials">
      <div className="hero-2">
        <h1>Power Up Your Business Your Way</h1>
        <br />
        <p>Know what's in it for Your Business?</p>
      </div>
      <div className="testimonial-slider-container">
        <div className="testimonial-slider">
          {testimonials.map((testimonial, index) => (
            <div
              className={`testimonial-card ${index === currentIndex ? 'active' : ''}`}
              key={index}
            >
              <TestimonialCard
                text={testimonial.text}
                image={testimonial.image}
                name={testimonial.name}
                role={testimonial.role}
              />
            </div>
          ))}
        </div>
      </div>
      <div>
        <button className="Slider-1" onClick={handleLeftClick}>
          <img src="assets/Left-Slider.png" alt="Left-Arrow" />
        </button>
        <button className="Slider-2" onClick={handleRightClick}>
          <img src="assets/Right-Slider.png" alt="Right-Arrow" />
        </button>
      </div>
    </section>
  );
}

function TestimonialCard({ text, image, name, role }) {
  return (
    <div className="testimonial-card">
      <p>{text}</p>
      <div className="testimonial-author">
        <img src={image} alt={name} className="author-photo" />
        <div className="author-info">
          <h4>{name}</h4>
          <span>{role}</span>
        </div>
      </div>
    </div>
  );
}



function TestCard() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const cardData = [
    {
      title: 'HR & Payroll + Field Service Management + Inventory Management + Document Management',
      subtitle: 'Mining Sector',
      description: 'Bespoke HR & Payroll, Field Service Management, Inventory Management, and Document Management solutions for the mining sector.',
    },
    {
      title: 'Inventory Management + Document Management',
      subtitle: 'Construction Sector',
      description: 'Customized solutions for efficient document and inventory management in the construction sector.',
    },
    // Add more cards if needed
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? cardData.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === cardData.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="test-card">
      
      <div className="text-section">
        <h1>Power Up Your Business, Your Way</h1>
        <p>Know what's in it for Your Business?</p>
      </div>

     
      <div className="carousel">
        <div className="carousel-inner" style={{ transform: `translateX(-${currentIndex * 50}%)` }}>
          {cardData.map((card, index) => (
            <div
              key={index}
              className={`carousel-card ${
                index === currentIndex ? 'active' : index === (currentIndex + 1) % cardData.length ? 'next' : 'inactive'
              }`}
            >
              <h2>{card.title}</h2>
              <hr />
              <h3>{card.subtitle}</h3>
              <p>{card.description}</p>
            </div>
          ))}
        </div>

        
        <div className="carousel-controls">
          <button className="carousel-button prev" onClick={handlePrev}>&#9664;</button>
          <button className="carousel-button next" onClick={handleNext}>&#9654;</button>
        </div>
      </div>
    </div>
  );
}*/}




function JoinWaitlistCard() {
  return (
    <div className="join-waitlist-card">
      <div className="waitlist-info">
        <h3 className='Upper_Heading'>Be a Part of</h3>

        

        <MediaQuery maxWidth={480}>
        <h2>The <span className='Color_Span'>Next Big </span>Platform</h2>
        </MediaQuery>

        <MediaQuery minWidth={480}>
        <h2>The <span className='Color_Span'>Next Big</span><br/>Platform</h2>
        </MediaQuery>
        <p>Let's revolutionize your business. Connect with us today!</p>

        <MediaQuery minWidth={480}>
        <Link to="/contact">
          <button className="join-waitlist-button"><img src='assets/Right Arrow.png' alt="Arrow" className='arrow-img'></img></button>
        </Link>
        </MediaQuery>

        
      </div>
      
      <div className="mockup-card">
        {/* Replace content with an image */}
        <img src="assets/welcomedashboard.png" alt="Mockup" className="mockup-image" />
      </div>
      <MediaQuery maxWidth={480}>
        <Link to="/contact">
          <button className="join-waitlist-button"><img src='assets/Right Arrow.png' alt="Arrow" className='arrow-img'></img></button>
        </Link>
        </MediaQuery>
    </div>
  );
}




function Testimonials() {
  return (
    <section className="testimonials">
      <div className="testimonials-content">
        <div className="text-section">
        <MediaQuery minWidth={480}>
          <h1>Power Up Your <br />Business,<br />Your Way</h1>
          </MediaQuery>
          <MediaQuery maxWidth={480}>
          <h1>Power Up Your Business, Your Way</h1>
          </MediaQuery>
          <p>Know what's in it for Your Business?</p>
        </div>
        <TestimonialsSlider />
      </div>
    </section>
  );
}

function TestimonialsSlider() {
  const [currentCard, setCurrentCard] = useState(0);

  const cardData = [
    {
      title: "HR & Payroll + Field Service Management + Inventory Management + Document Management",
      subtitle: "Mining Sector",
      description: "ipapo offers HR & Payroll, Field Service Management, Inventory Management, and Document Management solutions for the mining sector.",
    },
    {
      title: "HR & Payroll + Asset Management + Space Utilization + Maintenance Management ",
      subtitle: "Facility Management",
      description: "ipapo offers HR & Payroll, Asset Management,Space Utilization, and Maintainenece Management solutions for the Facility Management Sector.",
    },
    {
      title: "Field Service Management + Mobile Workforce Management + Real-time Data Collection",
      subtitle: "Field Service Management",
      description: "ipapo offers Field Service Management, Mobile Workforce Management, Real-time Data collection solutions for the Field Service Management Sector.",
    },
    {
      title: "Warehouse Management + Inventory Management + Asset Management + Barcode Scanning",
      subtitle: "Warehouse Management",
      description: "ipapo offers Warehouse Management, Inventory Management, Asset Management and Barcode Scanning solutions for the Warehouse Management Sector.",
    },
  ];

  const handlePrevClick = () => {
    setCurrentCard((prev) => (prev === 0 ? cardData.length - 1 : prev - 1));
  };

  const handleNextClick = () => {
    setCurrentCard((prev) => (prev === cardData.length - 1 ? 0 : prev + 1));
  };

  return (
    <div className="card-slider">
      <div
        className="cards-wrapper"
        style={{ transform: `translateX(-${currentCard * 100}%)` }}
      >
        {cardData.map((card, index) => (
          <div
            className={`card ${index === currentCard ? 'active' : ''}`}
            key={index}
          >
            <h2>{card.title}</h2>
            <div className="line"></div>
            <h3>{card.subtitle}</h3>
            <p>{card.description}</p>
          </div>
        ))}
      </div>
      <div classname="cardbutton">
        <button className="nav-button left" onClick={handlePrevClick}>
          <img src="assets/leftnav.png" alt="arrow" className="navarrow"></img>
        </button>
        <button className="nav-button right" onClick={handleNextClick}>
          <img src="assets/rightnav.png" alt="arrow" className="navarrow"></img>
        </button>
      </div>
    </div>
  );
}

{/*
function Footerlogo() {
  return (
    <img src="assets/opip.png" alt="ipapo logo" className="footerlogo" />

  )
}



function Footer() {
  return (
    <footer className="footer">

      <div className="footer-top">
        <h3 className="contact">Contact Us</h3>
        <div className="social-icons">

          <img src="assets/twitter.png" alt="ipapo logo" className="icon" />
          <img src="assets/facebook.png" alt="ipapo logo" className="icon" />
          <img src="assets/instagram.png" alt="ipapo logo" className="icon" />
          <img src="assets/github.png" alt="ipapo logo" className="icon" />

        </div>
      </div>
      <div className="footer-bottom">
        <p className="copyright">© 2024 ipapo All rights reserved</p>
        <div className="privacy-terms">
          <span>Privacy</span>
          <span>Terms</span>
        </div>
      </div>
    </footer>
  );
}
*/}

export default App;