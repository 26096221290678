import React from "react";
import './Footer.css';
import { Link } from "react-router-dom";


  
  
  
  function Footer() {
    return (
        
      <footer className="footer">
      <img src="assets/opip.png" alt="ipapo logo" className="footerlogo" />
  
        <div className="footer-top">
        <Link to="/contact" className="contactusbutton">
        <button className="contact">Contact Us</button>
      </Link>
          
          <div className="social-icons">
  
            {/*<img src="assets/twitter.png" alt="ipapo logo" className="icon" />*/}
            <a href="https://www.facebook.com/profile.php?id=61568258687224&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
            <img src="assets/facebook.png" alt="ipapo logo" className="icon" />
            </a>
            
            <a href="https://www.instagram.com/ipapo_official/" target="_blank" rel="noopener noreferrer">
            <img src="assets/instagram.png" alt="ipapo logo" className="icon" />
            </a>
            
            {/*<img src="assets/github.png" alt="ipapo logo" className="icon" />*/}
  
          </div>
        </div>
        <div className="footer-bottom">
          <p className="copyright">© 2024 ipapo All rights reserved</p>
          {/*<div className="privacy-terms">
            <span>Privacy</span>
            <span>Terms</span>
          </div>*/}
        </div>
      </footer>
    );
  }
  

  export default Footer;